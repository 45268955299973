


const paralaxObserver = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			entry.target.classList.add(entry.target.dataset.paralax);
			paralaxObserver.unobserve(entry.target);
		}
	});
});

document.addEventListener("DOMContentLoaded", () => {
	document.querySelectorAll(".paraX").forEach((elm) => {
		paralaxObserver.observe(elm);
	});
});
